import React from 'react'
import { Helmet } from 'react-helmet'

import { withStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core'

import { siteTheme, muiTheme } from './theme'

import SEO from './SEO'
import Header from './Header'
import Footer from './Footer'

// const theme = createMuiTheme({
//   palette: {
//     common: {
//       blue1: '#01EEFD',
//       blue2: '#198CFF',
//       blue3: '#0051A3',
//       blue4: '#00376E',
//       lightGrey: '#F8F8F8'
//     },
//     primary: {
//       main: '#198CFF',
//       light: '#01EEFD',
//       dark: '#00376E',
//       contrastText: '#FFFFFF'
//     },
//     // secondary
//     // error
//     background: {
//       default: '#F8F8F8'
//     }
//   },
//   typography: {
//     useNextVariants: true,
//     fontFamily: 'Raleway, sans-serif',
//     button: {
//       // textTransform: 'none'
//       fontWeight: 600
//     },
//     h1: {
//       fontSize: '3rem',
//       fontWeight: 700,
//       color: '#00376E'
//     },
//     h2: {
//       fontSize: '2.5rem',
//       fontWeight: 700,
//       color: '#00376E'
//     },
//     h3: {
//       fontSize: '2rem',
//       fontWeight: 700,
//       color: '#00376E'
//     }
//   }
// })

const styles = theme => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      width: 1100,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  page: {
    background: siteTheme.white
  },
  main: {
    // background: siteTheme.white
  }
})

function Layout (props) {
  const {
    classes,
    title,
    description,
    image,
    pathname,
    activeSection
  } = props

  return (
    <React.Fragment>
      <MuiThemeProvider theme={muiTheme}>
        <SEO title={title} description={description} image={image} pathname={pathname} />
        <Helmet>
          {/* Add fonts */}
          {/* <link href='https://fonts.googleapis.com/css?family=Raleway:300,300i,400,400i,500,500i,700,700i' rel='stylesheet' /> */}
          <link href='https://fonts.googleapis.com/css?family=Raleway:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i' rel='stylesheet' />
          {/* <link rel='stylesheet' href='https://fonts.googleapis.com/css?family=Roboto:300,400,500' /> */}
        </Helmet>
        {/* <div style={{ background: '#eaf6ff', backgroundImage: 'linear-gradient(to bottom right, #eaf6ff, #ffffff)' }}> */}
        <div className={classes.page}>
          <Header activeSection={activeSection} />
          <main className={classes.main}>
            {props.children}
          </main>
          <Footer />
        </div>
      </MuiThemeProvider>
    </React.Fragment>
  )
}

export default withStyles(styles)(Layout)
