'use stric'

import React from 'react'
import { graphql, Link } from 'gatsby'
import _ from 'lodash'

import ReactMarkdown from 'markdown-to-jsx'

import { Paper, Typography, withStyles } from '@material-ui/core'

import Layout from '../components/layout/Layout'

const styles = theme => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      width: 1100,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  topBanner: {
    paddingTop: '70px',
    background: 'linear-gradient(65deg, #198CFF 0%, #01EEFD 100%)',
    position: 'relative'
  },
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3
  }
})

const mdOptions = {
  overrides: {
    h1: { component: props => <Typography gutterBottom variant='h2' {...props} /> },
    h2: { component: props => <Typography gutterBottom variant='h3' {...props} /> },
    h3: { component: props => <Typography gutterBottom variant='subtitle1' {...props} /> },
    h4: { component: props => <Typography gutterBottom variant='caption' paragraph {...props} /> },
    p: { component: props => <Typography paragraph {...props} /> },
    li: {
      component: withStyles(styles)(({ classes, ...props }) => (
        <li className={classes.listItem}>
          <Typography component='span' {...props} />
        </li>
      ))
    }
  }
}

class SectionContentTemplate extends React.Component {
  render () {
    const {
      data,
      classes
    } = this.props
    const { strapiWebpage, allStrapiWebpage } = data
    const {
      // id,
      // name,
      label,
      path,
      title,
      description,
      content,
      image,
      webSection
    } = strapiWebpage

    // image is optional
    let imageSrc
    // if (image && image.childImageSharp && image.childImageSharp.fixed) {
    //   imageSrc = image.childImageSharp.fixed.src
    // }
    if (image && image.url) {
      imageSrc = image.url
    }

    // Section
    const sectionName = _.isNil(webSection) ? undefined : webSection.name

    // Section pages menu
    const pageMenuItems = allStrapiWebpage.edges.map(edge => {
      return (
        <Typography inline>
          <Link to={edge.node.path}>{edge.node.title}</Link>,
        </Typography>
      )
    })

    return (
      <Layout activeSection={sectionName} title={label} description={description} image={imageSrc} pathname={path}>
        <div className={classes.topBanner} />
        <div className={classes.layout}>
          <Paper className={classes.paper} elevation={1}>
            <Typography variant='h1'>
              {title}
            </Typography>
            <div>
              {pageMenuItems}
            </div>
            { imageSrc &&
              <div className={classes.imageContainer}>
                <img src={imageSrc} className={classes.image} />
              </div>
            }
            <ReactMarkdown options={mdOptions} classes={classes}>
              {content}
            </ReactMarkdown>
          </Paper>
        </div>
      </Layout>
    )
  }
}

export default withStyles(styles)(SectionContentTemplate)

export const pageQuery = graphql`
query(
  $id: String!
  $sectionId: String
) {
  strapiWebpage(id: { eq: $id }) {
    id
    name
    label
    path
    title
    description
    content
    image {
      url
    }
    webSection {
      id
      name
      label
      path
    }
  }
  allStrapiWebpage(
    filter: {
      webSection: {
        id: { eq: $sectionId }
      }
      id: { ne: $id }
      pageType: { ne: "Index" }
  }
    sort: {
      order: ASC,
      fields: [sortIndex, label]
    }
  ) {
      edges {
        node {
          id
          name
          label
          path
          title
          description
        }
      }
    }
  }
`
